.footer_container {
  height: 36.3rem;
  padding: 8rem 17rem 3rem 17rem;
  background: var(--color6);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.footer_container1 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 21rem;
}

.links_container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 2rem;
}

.links_container_main {
  display: flex;
  align-items: center;
  gap: 15rem;
}

.link_title,
.footer_cws {
  font-family: var(--Mulish);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
}

.link_title:hover {
  cursor: pointer;
}

.footer_handler {
  margin-top: 2.346rem;
  display: flex;
  align-items: center;
  gap: 2.596rem;

  img {
    height: 2.2rem;
    cursor: pointer;
  }
}

.footer_copyright {
  width: 100%;
  font-weight: 300;
  text-align: center;
}

.footer_container_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.mobile_logo {
  height: 11rem;
}

.footer_cws_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
}

@media (max-width: 76.8rem) {
  .footer_container {
    height: 30.5rem;
    padding: 2rem 2.6rem;
  }

  .footer_container1 {
    gap: 0rem;
    width: 100%;
  }

  .mobile_logo {
    height: 3.7rem;
  }

  .footer_container_main {
    flex-direction: column;
  }

  .links_container_main {
    width: 65%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0;
  }

  .links_container {
    gap: 0.8rem;
  }

  .link_title,
  .footer_cws {
    font-size: 1.2rem;
    line-height: 2.4rem;
  }

  .logo_main_class {
    height: 100%;
    width: 20%;
    display: flex;
    align-items: center;
  }

  .footer_cws_container {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
  }

  .footer_handler {
    margin-top: 1.7rem;
    display: flex;
    align-items: center;
    gap: 1.3rem;

    img {
      height: 2rem;
      cursor: pointer;
    }
  }
}