:root {
  --color: #000000;
  --color2: #FFFFFF;
  --color3: #006DBD;
  --color4: #000033;
  --color5: #00ADEF;
  --color6: #E6F7FE;
  --color7: #00003314;
  --color8: #000033F2;
  --color9: #F2F1F1;
  --color10: #6B7280;
  --color11: #029837;
  --color12: #F7FFF6;
  --Poppins: 'Poppins';
  --Ubuntu: 'Ubuntu';
  --Mulish: 'Mulish'
}

body {
  margin: 0;
  font-family: var(--Gemsbuck02), var(--Poppins), var(--Poppins), monospace, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: var(--Gemsbuck02), var(--Poppins), var(--Poppins), monospace, sans-serif;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

html {
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  /* Smooth scrolling */
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  font-size: 10px;
}

@font-face {
  font-family: Calibri;
  src: url('./assests/fonts/Calibri/calibri-regular.ttf');
}