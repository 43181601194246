.contact_us_container {
  display: flex;
  justify-content: space-between;
  padding: 10rem 12rem;
  background: var(--color2);
  font-family: var(--Ubuntu);

}

.contact_us_image {
  height: 51.788rem;
}

.contact_us_title {
  font-size: 5rem;
  font-weight: 700;
  line-height: 7.496rem;
  color: var(--color4);
}

.contact_details {
  width: 50.9rem;
}

.contact_us_desc {
  margin-top: 1.399rem;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 2.6rem;
  color: var(--color8);
  text-align: center;
}

.contact_form_container :last-child {
  margin-top: 2rem;
}

.contact_button {
  width: 100%;
  height: 5.4rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: var(--Ubuntu);
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.6rem;
  border-radius: 2rem;
  outline: none;
  border: none;
  color: var(--color2);
  background: var(--color11);
}

.contact_button:hover {
  cursor: pointer;
}

.name_email_input {
  height: 5.2rem;
  padding: 1.648rem 3.7568rem;
  border-radius: 1.734rem;
}

.contact_request {
  height: 13rem;
  padding: 2.8rem;
  border-radius: 1.734rem;
}

.contact_input::-webkit-input-placeholder {
  color: var(--color10);
}

.contact_input::-moz-placeholder {
  color: var(--color10);
}

.contact_input {
  margin-top: 2rem;
  width: 100%;
  display: block;
  background: var(--color9);
  border-color: transparent;
  border: none;
  font-family: var(--Mulish);
  font-size: 1.6rem;
  font-weight: 200;
  line-height: 2.081rem;
  letter-spacing: -0.01em;
}

.contact_input:-ms-input-placeholder {
  color: var(--color10);
}

.contact_input::placeholder {
  color: var(--color10);
  opacity: 1;
}


@media (max-width: 76.8rem) {
  .contact_us_container {
    position: relative;
    display: block;
    padding: 2rem 3rem;
    text-align: center;
  }

  .contact_us_image {
    margin-top: 8rem;
    height: 28rem;
  }

  .contact_us_title {
    font-size: 1.4rem;
    line-height: 5.25rem;
  }

  .contact_us_desc {
    margin-top: 0rem;
    font-size: 1rem;
    line-height: 2rem;
  }

  .contact_details {
    width: 100%;
  }

  .contact_us_quetions {
    position: absolute;
    top: 0;
    left: calc(100% - 80%);
  }

  .name_email_input {
    height: 3.4rem;
    padding: 1.079rem 2.263rem;
    border-radius: 1rem;
  }

  .contact_input {
    margin-top: 1.6rem;
    width: 100%;
    font-size: 1.2rem;
    line-height: 1.364rem;
    letter-spacing: -0.01em;
  }

  .contact_button {
    width: 100%;
    height: 3.5rem;
    font-size: 1.4rem;
    line-height: 1.703rem;
    border-radius: 1rem;
  }

  .contact_form_container :last-child {
    margin-top: 1.6rem;
  }

}