.class_info_container {
  width: 100%;
  height: 35.1rem;
  background: var(--color6);
  padding: 0 13rem;
}

.class_feature_container {
  flex-direction: column;

  .class_feature_logo {
    height: 5.8rem;
  }

  .feature_name {
    font-family: var(--Ubuntu);
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 5rem;
    color: var(--color4);
    margin-top: 2rem;
  }

  .feature_duration {
    font-family: var(--Poppins);
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 3rem;
    color: var(--color);
  }
}

@media (max-width: 76.8rem) {

  .class_info_container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2.8rem;
    padding: 3rem 5.2rem;
    height: max-content;
  }

  .class_feature_container {
    max-width: max-content;
    min-width: 8.2rem;

    .class_feature_logo {
      height: 3.351rem;
    }

    .feature_name {
      font-size: 1.4rem;
      margin-top: 1rem;
      font-size: 14px;
      line-height: 3.369rem;
    }

    .feature_duration {
      font-size: 1rem;
      line-height: 2.01rem;
    }
  }
}