.location_container {
  height: 43.7rem;
  background: var(--color12);
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1.6rem;
  padding: 3rem 26.7rem;
}

.location_card_container {
  padding: 4rem 5.5rem;
  width: 40.7rem;
  height: 22.3rem;
  border-radius: 2rem;
  background: var(--color2);
  box-shadow: 0rem 1rem 2.5rem 0rem var(--color7);
  font-family: var(--Ubuntu);
  color: var(--color4);
}

.card_title {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 3.75rem;
}

.card_email_container {
  margin-top: 1.6rem;
}

.card_iamge {
  height: 2rem;
  margin-right: 1.5rem;
  margin-top: 0.5rem;
}

.card_email {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 3rem;
}


@media (max-width: 76.8rem) {
  .location_container {
    height: max-content;
    gap: 1.6rem;
    padding: 3rem 4.9rem;
  }

  .location_card_container {
    height: max-content;
    padding: 2rem 1.25rem;
    width: 27.7rem;
    box-shadow: 0rem 1rem 2.5rem 0rem var(--color7);
  }

  .card_title {
    font-size: 1.4rem;
    line-height: 1.825rem;
  }

  .card_email_container {
    margin-top: 1rem;
  }

  .card_iamge {
    height: 2.2rem;
    margin-right: 1rem;
    margin-top: 0rem;

  }

  .card_email {
    font-size: 1.2rem;
    line-height: 3rem;
    line-height: 1.7rem;
  }
}