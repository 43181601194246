.learning_objectives_container {
  background: var(--color6);
  padding-bottom: 16.2rem;
}

.key_learnings_container {
  padding-top: 12.2rem;
  color: var(--color4);
  font-family: var(--Ubuntu);
  flex-direction: column;
}

.key_learnings_name {

  font-size: 3.5rem;
  font-weight: 700;
  line-height: 5.25rem;
}

.key_learnings_desc {
  margin-top: 2.6rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.6rem;
  width: 59.8rem;
  text-align: center;
  color: var(--color8);
}

.learning_objective_card_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 9rem;
  row-gap: 7rem;
}

.learning_objective_card {
  font-family: var(--Ubuntu);
  width: 38.7rem;
  height: 41rem;
  border-radius: 2rem;
  box-shadow: 0rem 1rem 2.5rem 0rem var(--color7);
  background: var(--color2);
  padding: 3rem;
}

.card_image {
  height: 5.3rem;
}

.card_name {
  font-size: 2rem;
  font-weight: 700;
  line-height: 3rem;
  margin-top: 2rem;
  color: var(--color4);
}

.card_desc {
  margin-top: 1rem;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 2.6rem;
  color: var(--color8)
}

/* Mobile + Tablet handling */
@media (max-width: 76.8rem) {
  .width_card {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .width_card_flex_end {
    justify-content: flex-end;
  }

  .learning_objectives_container {
    padding-bottom: 3rem;
  }

  .key_learnings_container {
    padding-top: 3rem;
  }

  .key_learnings_name {
    font-size: 1.4rem;
  }

  .card_image {
    height: 3.2rem;
  }

  .key_learnings_desc {
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.6rem;
    width: 31.7rem;
  }

  .learning_objective_card {
    width: 26.6rem;
    height: max-content;
    border-radius: 1rem;
    padding: 2rem;
    box-shadow: 0px 0.608rem 1.52rem 0rem var(--color7);
    overflow: hidden;
    margin-bottom: 1.6rem;
  }


  .card_name {
    font-size: 1.2rem;
    line-height: 1.825rem;
    margin-top: 0.6rem;
    float: none !important;
  }

  .learning_objective_card_container {
    display: block;
    margin-top: 3rem;
  }

  .card_desc {
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 1.581rem;
  }

}