.logo_container_main {
  width: max-content;
}

.logo_container_main:hover {
  cursor: pointer;
}

.full_logo {
  height: 10rem;
}