/* Customizing the Pagination Dots */
.swiper-pagination-bullet {
  width: 1rem;
  /* Custom width */
  height: 1rem;
  /* Custom height */
  background-color: #D9D9D9;
  /* Custom color */
  margin: 0 5rem;
  /* Adjust spacing between dots */
}

.swiper-pagination-bullet-active {
  background-color: var(--color5);
  /* Color for the active dot */
  transform: scale(1.2);
  /* Enlarge the active dot */
}

/* Customizing the Navigation Arrows */
.swiper-button-next,
.swiper-button-prev {
  height: 6.4rem;
  width: 3.4rem;
  background-color: rgba(255, 255, 255, 0.3);
  color: var(--color5);
  border-radius: 1rem;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 2rem;
  /* Arrow icon size */
}

.swiper-button-next {
  right: 1rem;
  /* Adjust position if needed */
}

.swiper-button-prev {
  left: 1rem;
  /* Adjust position if needed */
}

.swiper-wrapper {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.swiper {
  padding: 0 7rem;
}


@media (max-width: 76.8rem) {
  .swiper-pagination-bullet {
    height: 0.6rem;
    width: 0.6rem;
  }
  .swiper {
    padding: 0 2rem;
  }

  .swiper-wrapper {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .swiper-button-next,
  .swiper-button-prev {
    height: 3.4rem;
    width: 2.4rem;
    border-radius: 0.6rem;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 1.2rem;
  }

}