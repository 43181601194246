.testimonial_container {
  padding: 4rem 0rem;
  background: var(--color12);
  position: relative;
}

.testimonial_container_dot_image {
  position: absolute;
  right: 0;
  top: 0;
  height: 12.1rem;
  margin-top: 1rem;
  margin-right: 1rem;
}

.testimonial_container_dot_image1 {
  position: absolute;
  z-index: 5;
  bottom: 0;
  left: 0;
  height: 12.1rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
}

.testimonial {
  font-family: var(--Ubuntu);
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 5.25rem;
  text-align: center;
  color: var(--color4);
}

.testimonial_stories {
  margin-top: 5rem;
}

.testimonial_card_container {
  width: 39.4rem;
  height: 35.6rem;
  padding: 2.6rem;
  border-radius: 2rem;
  background: var(--color2);
  box-shadow: 0rem 1rem 2.5rem 0rem var(--color7);
  /* margin-left: 7rem; */
}

.testimonial_card__header_image {
  height: 5.6rem;
}

.testimonial_card_name_container {
  margin-left: 1.6rem;
}

.testimonial_card_name_designation {
  font-family: var(--Mulish);
  font-size: 1.3rem;
  line-height: 1.95rem;
  text-align: left;
}

.testimonial_card_name {
  font-weight: 700;
  color: var(--color4);

}

.testimonial_card_designation {
  font-weight: 300;
  color: var(--color8);
}

.testimonial_card_quote {
  transform: rotateY(-180deg);
  font-family: var(--Mulish);
  font-size: 4.8rem;
  font-weight: 700;
  color: var(--color5)
}

.testimonial_card_desc {
  margin-top: 2rem;
  font-family: var(--Ubuntu);
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 2.6rem;
  text-align: left;
  color: var(--color8);
}

@media (max-width: 76.8rem) {
  .testimonial_container {
    padding: 1rem 0;
  }

  .testimonial {
    font-size: 1.4rem;
  }

  .testimonial_stories {
    margin-top: 0rem;
  }

  .testimonial_container_dot_image1 {
    height: 6rem;
  }

  .testimonial_container_dot_image {
    height: 6rem;
  }

  .testimonial_card__header_image {
    height: 4.2rem;
  }

  .testimonial_card_quote {
    font-size: 4rem;
  }
  .testimonial_card_name_designation {
    font-size: 1.2rem;
  }

  .testimonial_card_desc {
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 2rem;
  }

  .testimonial_card_container {
    width: 100%;
    height:30rem;
    padding: 2rem;
    border-radius: 1rem;
  }

}