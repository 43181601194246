.home_container {
  width: 100%;
  margin-top: 5rem;
  display: flex;
  align-items: center;
  padding-bottom: 13.036rem;
}

.home_left_section_container {
  width: 58%;
}

.eucg {
  width: 90%;
  font-family: var(--Ubuntu);
  font-size: 4rem;
  font-weight: 700;
  line-height: 5rem;
  color: var(--color4);
}

.home_checklist_container {
  margin-top: 5.2rem;
}

.home_checklist_item_margin_top {
  margin-top: 1.6rem;
}

.home_checklist_container :first-child {
  margin-top: 0.4rem;
}

.home_checklist {
  margin-right: 1.5rem;

  img {
    height: 2.3rem;
  }
}

.home_checklist_item {
  font-family: var(--Poppins);
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 3rem;
  color: var(--color4);
}

.home_right_section_container {
  width: 42%;
}

.home_group_photo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  img {
    height: 53.664rem;
  }
}

.home_contact_info {
  width: 26rem;
  box-shadow: 0px 10px 25px 0px #0293341A;
  background: var(--color2);
  border-radius: 1.6rem;
  position: absolute;
  bottom: 5.7rem;
  left: 0;
  padding: 1.2rem 1.7rem;

  img {
    height: 4.7rem;
  }
}

.phone_call_number {
  font-family: var(--Ubuntu);
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 5rem;
  margin-left: 0.4rem;
}

.home_group_photo_container {
  position: relative;
}

@media (max-width: 76.8rem) {
  .home_container {
    margin-top: 2.642rem;
    flex-direction: column-reverse;
    padding-bottom: 1rem;
  }

  .home_group_photo {
    justify-content: space-around;
    img {
      height: 28rem;
    }
  }

  .home_right_section_container {
    width: 100%;
  }

  .home_left_section_container {
    width: 100%;
  }

  .home_contact_info {
    width: 13.4rem;
    box-shadow: 0px 0.522rem 1.304rem 0rem #0293341A;
    border-radius: 0.835rem;
    bottom: 5.7rem;
    padding: 0.896rem 0.7rem;

    img {
      height: 2.452rem;
    }
  }

  .phone_call_number {
    font-size: 1.252rem;
    font-weight: 500;
    margin-left: 0.6rem;
    font-size: 12.52px;
    line-height: 2.609rem;
  }

  .eucg {
    margin-top: 2rem;
    width: 100%;
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 3.5rem;
    color: var(--color4);
  }


  .home_checklist_container {
    margin-top: 1rem;
  }

  .home_checklist_item_margin_top {
    margin-top: 0.8rem;
  }

  .home_checklist {
    margin-right: 0.833rem;

    img {
      height: 1.333rem;
    }
  }

  .home_checklist_item {
    font-size: 1.2rem;
  }
}