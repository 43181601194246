.homepage_conatiner {
  /* padding: 3rem 7.2rem; */

  .container_fluid {
    padding-left: 7.2rem;
    padding-right: 7.2rem;
  }

  .container_fuild_padding_top {
    padding-top: 3rem;
  }

  .container_fuild_padding_bottom {
    padding-bottom: 3rem;
  }

  .d-flex {
    display: flex;
  }

  .center-align-item {
    align-items: center;
  }

  .start-align-item {
    align-items: flex-start;
  }

  .jstify-space-between {
    justify-content: space-between;
  }

  .w-100 {
    width: 100%;
  }

  .jstify-space-around {
    justify-content: space-around;
  }
}

@media (max-width: 76.8rem) {
  .homepage_conatiner {
    .container_fluid {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .container_fuild_padding_top {
      padding-top: 1.6rem;
    }
  }
}