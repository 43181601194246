.class_gallery_container {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.class_gallery_images {
  height: 28rem;
  width: 37.3rem;
  object-fit: fill;
}



@media (max-width: 76.8rem) {
  .class_gallery_images {
    height: 15.7rem;
    width: 98%;
    object-fit: fill;
  }

  .class_gallery_container {
    padding: 0 0;
    padding-bottom: 0;
  }

}