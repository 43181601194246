.item_gap {
  display: flex;
  /* or display: grid; */
  gap: 4rem;
}

.nav_item {
  font-family: var(--Poppins);
}

.nav_item_name {
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 2.4rem;
  color: var(--color4);
}

.active_element {
  opacity: 1 !important;
}

.active {
  margin-top: 0.7rem;
  height: 0.28rem;
  opacity: 0;
  border-radius: 0.2rem;
  background: var(--color4);
  transition: opacity 0.3s ease-in;
}

.nav_item:hover {
  cursor: pointer;

  .nav_item_name {
    font-weight: 400;
  }

  .active {
    opacity: 1;
  }
}

.contact_us {
  width: 15.7rem;
  height: 5.135rem;
  font-family: var(--Ubuntu);
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  letter-spacing: -0.01em;
  color: var(--color5);
  border-radius: 1.6rem;
  border: 2px solid var(--color5);
  margin-left: 2.6rem;
}

.contact_us:hover {
  cursor: pointer;
}

.header {
  padding-top: 1.4rem;
  position: sticky;
  top: 0;
  z-index: 1000;
  background: var(--color2);
  transition: all ease-in 0.5s;

  img {
    padding: 0;
    height: 9rem;
    transition: all ease-in 0.5s;
  }
}

.scrolled {
  box-shadow: 0px 10px 8.4px 0px #0000000D;
  transition: all ease-in 0.5s;
  padding-bottom: 1.4rem;

  img {
    height: 7rem;
    transition: all ease-in 0.5s;
  }
}

/* Mobile header csss */
.mobile_header_container {
  position: sticky;
  top: 0;
  z-index: 1000;
  background: var(--color2);
  padding-bottom: 1.4rem;

  img {
    height: 3.757rem
  }

  .menu_icon {
    img {
      height: 2.4rem;
      margin-left: 1.6rem;
      cursor: pointer;
    }
  }

}

.mobile_scrolled {
  box-shadow: 0px 10px 8.4px 0px #0000000D;
  transition: box-shadow ease-in 0.5s;
}

.side_menu_container {
  top: 0;
  right: 0;
  position: fixed;
  height: 100%;
  z-index: 10000;
  width: 0;
  background: var(--color2);
  overflow-x: hidden;
  transition: width 0.6s ease-in-out;
}

.open_side_Menu_width {
  width: 100%
}

.close_container {
  padding: 1.6rem;

  img {
    height: 2.4rem;
    cursor: pointer;
  }

  .nav_item_container {
    padding-left: calc(3rem - 1.6rem);
    margin-top: 2.3rem;
  }

  .nav_item_title {
    min-width: 10rem;
    font-family: var(--Ubuntu);
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 3.5rem;
    text-align: left;
  }

  .nav_item_title_normal {
    color: var(--color4);
  }

  .nav_item_title_active {
    color: var(--color5);
  }

  .nav_item_title:hover {
    cursor: pointer;
  }
}

@media (max-width: 76.8rem) {
  .contact_us {
    width: 7.9rem;
    height: 2.4rem;
    font-family: var(--Ubuntu);
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2.4rem;
    letter-spacing: -0.01em;
    color: var(--color5);
    border-radius: 1rem;
    border: 1px solid var(--color5);
    margin-left: 0;
  }
}